import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";
import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, Link, useActionData, useNavigation, useSearchParams } from "@remix-run/react";
import { z } from "zod";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { rateLimit, COMMON_LIMITS } from "~/lib/rate-limit";
import { FormItem, FormLabel, FormError } from "~/components/ui/form";
import { verifyLogin } from "~/models/user.server.ts";
import { createUserSession, getUserId } from "~/session.server.ts";
import { safeRedirect } from "~/utils.ts";
import { TimeAttackDefender } from "~/lib/utils";

export const meta: MetaFunction = () => [{ title: "Login · BGHW Leiter-Check" }];

const schema = z.object({
  email: z
    .string()
    .trim()
    .max(256)
    .email()
    .transform((val) => val.toLowerCase()),
  password: z.string().min(8, "Password muss mindestens 8 Zeichen lang sein."),
  redirectTo: z
    .string()
    .optional()
    .transform((val) => safeRedirect(val, "/inspect")),
});

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const userId = await getUserId(request);
  if (userId) return redirect("/inspect");
  return json({});
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const tad = new TimeAttackDefender(2000);
  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema });

  // submission not valid?
  if (submission.status !== "success") {
    return json(submission.reply(), { status: submission.status === "error" ? 400 : 200 });
  }

  await rateLimit(request, COMMON_LIMITS.LOGIN);

  const { email, password, redirectTo } = submission.value;

  const user = await verifyLogin(email, password);

  if (!user) {
    await tad.wait();
    return json(
      submission.reply({
        fieldErrors: {
          email: ["Falsche E-Mail oder Passwort."],
        },
      }),
      { status: 400 },
    );
  }

  if (user.blocked) {
    await tad.wait();
    return json(
      submission.reply({
        fieldErrors: {
          email: ["Dieser Account wurde gesperrt."],
        },
      }),
      { status: 403 },
    );
  }

  await tad.wait();
  return createUserSession({
    redirectTo,
    remember: true,
    request,
    userId: user.id,
  });
};

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/inspect";
  const { state } = useNavigation();
  const loading = state !== "idle";

  const lastResult = useActionData<typeof action>();
  const [form, { email, password }] = useForm({
    lastResult,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema });
    },
  });

  return (
    <main>
      <h2 className="mb-6 text-2xl font-semibold">Login</h2>
      <Form method="post" {...getFormProps(form)}>
        <FormItem errors={email.errors}>
          <FormLabel>E-Mail</FormLabel>
          <Input
            required
            className="w-full"
            autoComplete="username"
            placeholder="demo@example.com"
            {...getInputProps(email, { type: "email" })}
          />
          <FormError />
        </FormItem>

        <FormItem errors={password.errors}>
          <FormLabel>Passwort</FormLabel>
          <Input
            required
            autoComplete="current-password"
            className="w-full"
            placeholder="********"
            {...getInputProps(password, { type: "password" })}
          />
          <div className="flex justify-end">
            <Button asChild variant="link" className="m-0 h-auto p-0">
              <Link to="/forgot-password" unstable_viewTransition>
                Passwort vergessen?
              </Link>
            </Button>
          </div>
          <FormError />
        </FormItem>

        <input type="hidden" name="redirectTo" value={redirectTo} />
        <Button disabled={loading} type="submit" className="mt-4 w-full shadow-md">
          Einloggen
        </Button>
      </Form>

      <hr className="mx-4 mb-8 mt-16" />
      <div>
        <div className="text-center text-sm text-gray-800">
          <div className="">Noch keinen Account?</div>
          <Button asChild variant="outline" className="mt-4 w-full shadow-md">
            <Link
              to={{
                pathname: "/register",
                search: searchParams.toString(),
              }}
              unstable_viewTransition
            >
              Jetzt registrieren
            </Link>
          </Button>
        </div>
      </div>
    </main>
  );
}
